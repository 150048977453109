@if (control.invalid) {
    <div class="validation-messages font-body-xs-semibold font-color-error-400">
        @if (control.errors.required) {
            <span>
                {{ 'VALIDATION.RequiredField' | translate }}
            </span>
        } @else if (minLength && control.errors.minlength) {
            <span>
                {{ ('VALIDATION.MinLength' | translate).replace('X', minLength) }}
            </span>
        } @else if (maxLength && control.errors.maxlength) {
            <span>
                {{ ('VALIDATION.MaxLength' | translate).replace('X', maxLength) }}
            </span>
        } @else if (greaterOrEqualThan && control.errors.greaterOrEqualThan) {
            <span>
                {{ ('VALIDATION.GreaterOrEqualThan' | translate).replace('X', greaterOrEqualThan) }}
            </span>
        } @else if (smallerOrEqualThan && control.errors.smallerOrEqualThan) {
            <span>
                {{ ('VALIDATION.SmallerOrEqualThan' | translate).replace('X', smallerOrEqualThan) }}
            </span>
        } @else if (greaterThan && control.errors.greaterThan) {
            <span>
                {{ ('VALIDATION.GreaterThan' | translate).replace('X', greaterThan) }}
            </span>
        } @else if (!greaterThan && control.errors.greaterThan) {
            <span>
                {{ 'VALIDATION.GreaterThanPrevious' | translate }}
            </span>
        } @else if (smallerThan && control.errors.smallerThan) {
            <span>
                {{ ('VALIDATION.SmallerThan' | translate).replace('X', smallerThan) }}
            </span>
        } @else if (control.errors.emailIsNotVale) {
            <span>
                {{ 'VALIDATION.EmailIsNotVale' | translate }}
            </span>
        } @else if (!control.errors.emailIsNotVale && control.errors.emailInvalid) {
            <span>
                {{ 'VALIDATION.EmailInvalid' | translate }}
            </span>
        } @else if (control.errors.biggerThanNow) {
            <span>
                {{ 'VALIDATION.Prefix' | translate }}
                {{ 'VALIDATION.BiggerThanNow' | translate }}
            </span>
        } @else if (control.errors.lowerThanNow) {
            <span>
                {{ name }}
                {{ 'VALIDATION.LowerThanNow' | translate }}
            </span>
        } @else if (control.errors.shutdownInfoDisabled) {
            <span>
                {{ 'VALIDATION.ShutdownInfoDisabled' | translate }}
            </span>
        } @else if (control.errors.notEmpty) {
            <span>
                {{ 'VALIDATION.NotEmpty' | translate }}
            </span>
        }
    </div>
}
