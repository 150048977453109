import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Actions } from '@app/core/utils/enums/actions.enum';
import { AdherenceModalType } from '@app/core/utils/enums/adherence-dialog-type.enum';
import { RequiredJustificativeEnum } from '@app/core/utils/enums/required-justificative.enum';
import { FunctionalLocationPicklistModel } from '@app/shared/models/functional-location-picklist.model';
import { LocalizationAndPlanningCenterModel } from '@app/shared/models/localization-and-planning-center.model';
import { ShiftOptions } from '@app/shared/models/shift-options.model';
import { OptionsModel } from '@app/shared/models/shutdown-maintenance-info.model';
import {
    InitialValuesModel,
    JustificationCheckModel,
    MaintenanceShutdownFormResponseModel,
    ShutdownManagementFormModel,
} from '@app/shared/models/shutdown-management-form.model';
import {
    DisplayModalModel,
    MaintenanceShutdownAdherenceConfirmModel,
} from '@app/shared/models/shutdown-management.model';
import { AuxiliaryService } from '@app/shared/services/api/auxiliary.service';
import { LocalizationCenterService } from '@app/shared/services/api/localization-center.service';
import { PlanningCenterService } from '@app/shared/services/api/planning-center.service';
import { ShutdownManagementInfoService } from '@app/shared/services/api/shutdown-management-info.service';
import { ShutdownManagementService } from '@app/shared/services/api/shutdown-management.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { InvalidFormDataEnum } from '../enums/invalid-form-data.enum';
import { ShutdownManagementStorageService } from '../services/shutdown-management-storage.service';
import { ShutdownManagementFormStore } from './shutdown-management-form.store';

@Injectable({
    providedIn: 'root',
})
export class MaintenanceShutdownFormStore {
    private _maintenanceShutdownFormData = new ShutdownManagementFormModel();
    private _action: Actions;
    private _plannedDatesDirty: boolean;
    private _actualDatesDirty: boolean;
    private _initialValues: MaintenanceShutdownFormResponseModel;

    private _initialValues$ = new BehaviorSubject<InitialValuesModel>(null);
    private _displayModal$ = new BehaviorSubject<DisplayModalModel>(null);
    private _invalidData: InvalidFormDataEnum[] = [];
    private _invalidData$ = new BehaviorSubject<InvalidFormDataEnum[]>(null);

    private _observers = new Subscription();

    constructor(
        private planningCenterService: PlanningCenterService,
        private localizationCenterService: LocalizationCenterService,
        private auxiliaryService: AuxiliaryService,
        private shutdownManagementFormStore: ShutdownManagementFormStore,
        private shutdownManagementService: ShutdownManagementService,
        private shutdownManagementStorageService: ShutdownManagementStorageService,
        private shutdownManagementInfoService: ShutdownManagementInfoService
    ) {}

    init(): void {
        this._invalidData = [
            InvalidFormDataEnum.FunctionalLocations,
            InvalidFormDataEnum.MaintenanceShutdownForm,
        ];
        this._invalidData$.next(this._invalidData);
        this._observers.add(
            this.shutdownManagementFormStore.maintenanceShutdown.subscribe(async data => {
                if (data) {
                    if (data.maintenanceShutdownFormData?.id) {
                        this._maintenanceShutdownFormData.id = data.maintenanceShutdownFormData.id;
                    }
                    this._action = data.action;
                    this._initialValues = data.maintenanceShutdownFormData;
                    this._initialValues$.next({
                        data: data.maintenanceShutdownFormData,
                        action: this._action,
                    });
                }
            })
        );
    }

    get initialValues(): Observable<InitialValuesModel> {
        return this._initialValues$.asObservable();
    }

    get invalidData(): Observable<InvalidFormDataEnum[]> {
        return this._invalidData$.asObservable();
    }

    get displayModal(): Observable<DisplayModalModel> {
        return this._displayModal$.asObservable();
    }

    get planningCenters(): Observable<LocalizationAndPlanningCenterModel[]> {
        return this.planningCenterService.getAll();
    }

    get shiftOptions(): Observable<ShiftOptions[]> {
        return this.auxiliaryService.getShiftOptions();
    }

    get localizationCenters(): Observable<LocalizationAndPlanningCenterModel[]> {
        return this.localizationCenterService.getLocalizations();
    }

    getPhaseOptions(selectedLocalizationCenter: string): Observable<OptionsModel[]> {
        return this.shutdownManagementInfoService.getPhase(selectedLocalizationCenter);
    }
    private hasPreviousDates = (): boolean => {
        return (
            this._maintenanceShutdownFormData.previousStartDate !== null &&
            this._maintenanceShutdownFormData.previousEndDate !== null
        );
    };

    updatePreviousDates(): void {
        if (!this.hasPreviousDates()) {
            this._maintenanceShutdownFormData = {
                ...this._maintenanceShutdownFormData,
                previousStartDate: this._maintenanceShutdownFormData.startDate,
                previousEndDate: this._maintenanceShutdownFormData.endDate,
            };
        }
    }

    setMaintenanceShutdownFormData(formGroup: FormGroup): void {
        const formValue = formGroup.getRawValue();
        if (!formGroup.value.phase && this._maintenanceShutdownFormData.phase) {
            formGroup.value.phase = this._maintenanceShutdownFormData.phase;
        }

        this._maintenanceShutdownFormData = {
            ...this._maintenanceShutdownFormData,
            ...formValue,
        };

        const maintenanceShutdownFormInvalid = formGroup.invalid;
        this.setInvalidData(
            maintenanceShutdownFormInvalid,
            InvalidFormDataEnum.MaintenanceShutdownForm
        );

        this.updateFormDataState();

        if (this._initialValues) {
            this._plannedDatesDirty =
                this._initialValues.startDate !== this._maintenanceShutdownFormData.startDate ||
                this._initialValues.endDate !== this._maintenanceShutdownFormData.endDate;
            this._actualDatesDirty =
                this._initialValues.actualStart !== this._maintenanceShutdownFormData.actualStart ||
                this._initialValues.actualEnd !== this._maintenanceShutdownFormData.actualEnd;
        }
    }

    setFunctionalLocations(functionalLocationPicklist: FunctionalLocationPicklistModel): void {
        this._maintenanceShutdownFormData.functionalLocationGroupId =
            functionalLocationPicklist.selectedFunctionalLocationGroup?.id;
        this._maintenanceShutdownFormData.functionalLocations =
            functionalLocationPicklist.targetItems;
        if (functionalLocationPicklist.selectedFunctionalLocationGroup) {
            this._maintenanceShutdownFormData.functionalLocationsGroupExceptions =
                functionalLocationPicklist.sourceItems;
        } else {
            this._maintenanceShutdownFormData.functionalLocationsGroupExceptions = [];
        }

        this.shutdownManagementFormStore.setSelectedFunctionalLocations(
            functionalLocationPicklist.targetItems
        );
        this.setInvalidData(
            functionalLocationPicklist.invalidFunctionalLocationsSelection,
            InvalidFormDataEnum.FunctionalLocations
        );
        this.updateFormDataState();
    }

    setInvalidData(isInvalid: boolean, invalidObject: InvalidFormDataEnum): void {
        if (isInvalid && this._invalidData.indexOf(invalidObject) === -1) {
            this._invalidData.push(invalidObject);
        } else if (!isInvalid) {
            this._invalidData = this._invalidData.filter(data => data !== invalidObject);
        }
        this._invalidData$.next(this._invalidData);
    }

    updateFormDataState(): void {
        this.shutdownManagementFormStore.setMaintenanceShutdownFormData(
            this._maintenanceShutdownFormData
        );
        if (
            this._action === Actions.Add &&
            (this._maintenanceShutdownFormData.planningCenter ||
                this._maintenanceShutdownFormData.localizationCenter)
        ) {
            this.shutdownManagementStorageService.setWorkInProgress(
                this._maintenanceShutdownFormData
            );
        }
    }

    checkAdherence(confirmation: MaintenanceShutdownAdherenceConfirmModel): void {
        if (this._invalidData.length === 0) {
            const startDate = new Date(this._maintenanceShutdownFormData.startDate);

            switch (this._action) {
                case Actions.Update: {
                    if (this._plannedDatesDirty && !confirmation.plannedDates) {
                        this.checkJustification(confirmation);
                        return;
                    }
                    break;
                }

                default: {
                    if (
                        new Date(startDate.getFullYear(), startDate.getMonth(), 1) <= new Date() &&
                        !confirmation.newShutdown
                    ) {
                        this._displayModal$.next({
                            type: AdherenceModalType.NewShutdownNotAdherent,
                            confirmation,
                        });
                        return;
                    }
                    break;
                }
            }

            if (
                this._maintenanceShutdownFormData.actualEnd &&
                (this._action === Actions.Add ||
                    (this._action === Actions.Update && this._actualDatesDirty)) &&
                !confirmation.nonAdherence
            ) {
                this.checkStatus(confirmation);
                return;
            }

            this.shutdownManagementFormStore.saveMaintenanceShutdown();
        }
    }

    private hasRequiredJustificative = (
        requiredJustificative: RequiredJustificativeEnum,
        data: JustificationCheckModel
    ): boolean => {
        return data.requiredJustificatives.includes(requiredJustificative);
    };

    checkJustification(confirmation: MaintenanceShutdownAdherenceConfirmModel): void {
        this.shutdownManagementService
            .checkJustification(
                this._maintenanceShutdownFormData.id,
                new Date(this._maintenanceShutdownFormData.startDate).toISOString(),
                new Date(this._maintenanceShutdownFormData.endDate).toISOString()
            )
            .subscribe(data => {
                if (this.hasRequiredJustificative(RequiredJustificativeEnum.CannotUpdate, data)) {
                    this._displayModal$.next({
                        type: AdherenceModalType.CannotUpdate,
                        data,
                        confirmation,
                    });
                } else if (
                    this.hasRequiredJustificative(RequiredJustificativeEnum.Workflow, data)
                ) {
                    this._displayModal$.next({
                        type: AdherenceModalType.WorkflowRequired,
                        data: { ...data, shutdown: this._maintenanceShutdownFormData },
                        confirmation,
                    });
                } else if (
                    this.hasRequiredJustificative(RequiredJustificativeEnum.Date, data) ||
                    this.hasRequiredJustificative(RequiredJustificativeEnum.Duration, data)
                ) {
                    this._displayModal$.next({
                        type: AdherenceModalType.PlannedDatesChange,
                        data,
                        confirmation,
                    });
                } else {
                    confirmation.plannedDates = true;
                    this.checkAdherence(confirmation);
                }
            });
    }

    checkStatus(confirmation: MaintenanceShutdownAdherenceConfirmModel): void {
        this.shutdownManagementService
            .checkStatus(
                this._maintenanceShutdownFormData.id,
                new Date(this._maintenanceShutdownFormData.startDate).toISOString(),
                new Date(this._maintenanceShutdownFormData.endDate).toISOString(),
                new Date(this._maintenanceShutdownFormData.actualStart).toISOString(),
                new Date(this._maintenanceShutdownFormData.actualEnd).toISOString()
            )
            .subscribe(data => {
                confirmation.nonAdherence = data.isAdherentByDate && data.isAdherentByDuration;
                // confirmation.nonAdherentHistoryPlannedDates =
                //     data.plannedDateHistoryNonAdherentByDate.length === 0 &&
                //     data.plannedDateHistoryNonAdherentByDuration.length === 0;

                if (confirmation.nonAdherence) {
                    this.checkAdherence(confirmation);
                    return;
                }

                if (!confirmation.nonAdherence) {
                    this._displayModal$.next({
                        type: AdherenceModalType.NonAdherentJustificative,
                        data,
                        confirmation,
                    });
                }

                // if (!confirmation.nonAdherentHistoryPlannedDates) {
                //     const actualDates = [
                //         this._maintenanceShutdownFormData.actualStart,
                //         this._maintenanceShutdownFormData.actualEnd,
                //     ];
                //     this._displayModal$.next({
                //         type: AdherenceModalType.NonAdherentPlannedDatesHistory,
                //         data: {
                //             plannedDateHistoryNonAdherentByDate:
                //                 data.plannedDateHistoryNonAdherentByDate,
                //             plannedDateHistoryNonAdherentByDuration:
                //                 data.plannedDateHistoryNonAdherentByDuration,
                //             actualDates,
                //         },
                //         confirmation,
                //     });
                // }
            });
    }

    destroyService(): void {
        this._maintenanceShutdownFormData = new ShutdownManagementFormModel();
        this._action = null;
        this._observers.unsubscribe();
        this._initialValues$.next(null);
        this._displayModal$.next(null);
        this._invalidData$.next(null);
    }
}
