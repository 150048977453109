import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Actions } from '@app/core/utils/enums/actions.enum';
import { IocInformationTypesEnum } from '@app/core/utils/enums/ioc-information-types.enum';
import { CompareObjects } from '@app/core/utils/helpers/compare-objects';
import { InvalidFormDataEnum } from '@app/modules/shutdown-management/enums/invalid-form-data.enum';
import { MaintenanceShutdownFormStore } from '@app/modules/shutdown-management/store/maintenance-shutdown-form.store';
import { ShutdownManagementFormStore } from '@app/modules/shutdown-management/store/shutdown-management-form.store';
import { ShutdownInfoModel } from '@app/shared/models/shutdown-info.model';
import {
    OptionsModel,
    ShutdownMaintenanceInfoModel,
} from '@app/shared/models/shutdown-maintenance-info.model';
import { ShutdownManagementInfoService } from '@app/shared/services/api/shutdown-management-info.service';
import { clearDisabledOptionShutdownInfo } from '@app/shared/validators/shutdown-info-disabled.validator';
import { Observable, Subscription, distinctUntilChanged, filter, of } from 'rxjs';

@Component({
    selector: 'app-shutdown-info',
    templateUrl: './shutdown-info.component.html',
    styleUrls: ['./shutdown-info.component.scss'],
})
export class ShutdownInfoComponent implements OnInit, OnDestroy {
    @Input() shutdownInfoValue$ = of(new ShutdownInfoModel());
    @Input() localizationCenter$: Observable<{ localizationCenter: string }>;
    @Input() disabled$ = of(false);
    @Input() markAllAsTouched$: Observable<void>;
    @Input() requiredFields: string[];
    @Input() action: string;
    @Input() disablePhase: boolean;
    @Input() onlyActiveOptions: boolean;
    @Output() shutdownInfoChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

    formGroup: FormGroup;
    options = new ShutdownMaintenanceInfoModel();
    areaManagementOptions: OptionsModel[];
    shutdownInfoSubscriber: Subscription;
    Actions = Actions;
    shutdownInfoOriginalValue: ShutdownInfoModel;

    constructor(
        private fb: FormBuilder,
        private shutdownManagementInfoService: ShutdownManagementInfoService,
        private compareObjects: CompareObjects,
        private shutdownManagementFormStore: ShutdownManagementFormStore,
        private maintenanceShutdownFormStore: MaintenanceShutdownFormStore
    ) {}

    get iocInformationTypes(): typeof IocInformationTypesEnum {
        return IocInformationTypesEnum;
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            system: new FormControl(null, []),
            unit: new FormControl(null, []),
            category: new FormControl(null, []),
            equipment: new FormControl(null, []),
            workshop: new FormControl(null, []),
            fleet: new FormControl(null, []),
            responsibleName: new FormControl(null, []),
            responsibleManagement: new FormControl(null, []),
            executiveBoard: new FormControl(null, []),
            executiveManagement: new FormControl(null, []),
            areaManagement: new FormControl({ value: null, disabled: true }, []),
        });

        if (!this.disablePhase) {
            this.formGroup.addControl('phase', new FormControl(null, [Validators.required]));
        }

        this.setValidators();
        this.getLocalizationCenter();
        this.getFormGroupState();
        this.markAllAsTouched();
        this.listenValueChanges();
    }

    listenValueChanges(): void {
        this.formGroup.valueChanges
            .pipe(distinctUntilChanged(this.compareObjects.filledValuesAreEqual))
            .subscribe(() => {
                const isInvalid = Object.keys(this.formGroup.controls).some(c => {
                    return this.formGroup.get(c).errors?.required;
                });

                this.maintenanceShutdownFormStore.setInvalidData(
                    isInvalid,
                    InvalidFormDataEnum.ShutdownInfoForm
                );

                this.shutdownInfoChange.emit(this.formGroup);
            });
    }

    getLocalizationCenter(): void {
        this.localizationCenter$
            ?.pipe(filter(data => data !== null))
            .pipe(distinctUntilChanged())
            .subscribe(({ localizationCenter }) => {
                if (localizationCenter) {
                    this.cleanForm();
                    this.setCoiOptions(localizationCenter);
                }
            });
    }

    getShutdownInfoValue(): void {
        this.shutdownInfoValue$.subscribe(shutdownInfoValue => {
            this.getFormData(shutdownInfoValue);
        });
    }

    getFormGroupState(): void {
        this.disabled$.pipe(distinctUntilChanged()).subscribe(disabled => {
            this.enableOrDisableForm(disabled);
        });
    }

    markAllAsTouched(): void {
        this.markAllAsTouched$?.subscribe(() => {
            this.formGroup.markAllAsTouched();
        });
    }

    setValidators(): void {
        if (this.requiredFields) {
            for (const field of this.requiredFields) {
                this.form[field].setValidators(Validators.required);
            }
        }
    }

    getFormData(shutdownInfoValue: ShutdownInfoModel): void {
        this.formGroup?.patchValue({ ...shutdownInfoValue });
        this.shutdownInfoOriginalValue = shutdownInfoValue;

        this.clearInactiveOptions();
        this.setAreaManagement();
    }

    get form(): { [key: string]: AbstractControl } {
        return this.formGroup.controls;
    }

    setCoiOptions(localizationCenter: string): void {
        this.options = new ShutdownMaintenanceInfoModel();
        this.shutdownInfoSubscriber = this.shutdownManagementInfoService
            .getAllShutdownManagementInfo(localizationCenter, this.onlyActiveOptions, false)
            .subscribe(coiOptions => {
                this.options = coiOptions;
                if (this.fillUniqueOptions()) {
                    this.formGroup.updateValueAndValidity();

                    this.shutdownManagementFormStore.setMaintenanceShutdownInfoAvailableData(
                        coiOptions
                    );

                    if (
                        this.form.executiveManagement.value &&
                        (!this.action || this.action === Actions.Add)
                    ) {
                        this.searchAreaManagement(this.form.executiveManagement.value);
                    }
                }

                this.getShutdownInfoValue();
            });
    }

    enableOrDisableForm(disabled: boolean): void {
        if (disabled) {
            this.formGroup.disable();
            return;
        }

        this.formGroup.enable();

        if (!this.form.executiveManagement.value) {
            this.form.areaManagement.disable();
        }
    }

    fillUniqueOptions(): boolean {
        let hasChanges = false;
        for (const key in this.options) {
            if (this.options[key].length === 1 && this.action !== Actions.View) {
                this.form[key]?.patchValue(this.options[key][0].code, { emitEvent: false });
                hasChanges = true;
            }
        }
        return hasChanges;
    }

    clearInactiveOptions(): void {
        Object.keys(this.form).forEach(key => {
            const options = this.options[key];
            const control = this.formGroup.get(key);
            clearDisabledOptionShutdownInfo(control, options);
        });
    }

    cleanForm(): void {
        this.formGroup.patchValue({ ...new ShutdownInfoModel() }, { emitEvent: false });
    }

    searchAreaManagement(executiveManagementId: number): void {
        if (this.areaManagementOptions?.[0]?.code !== executiveManagementId) {
            this.shutdownManagementInfoService
                .getAreaManagementByExecutiveManagementId(
                    [executiveManagementId],
                    null,
                    this.onlyActiveOptions
                )
                .subscribe(response => {
                    this.areaManagementOptions = response;

                    if (this.formGroup.enabled) {
                        this.form.areaManagement.enable();
                    }

                    if (response.length === 1) {
                        this.formGroup.patchValue({ areaManagement: response[0].code });
                    }
                    this.clearInactiveOptions();
                });
        }
    }

    ngOnDestroy(): void {
        this.shutdownInfoSubscriber?.unsubscribe();
    }

    setAreaManagement(): void {
        if (this.form.executiveManagement.value) {
            this.searchAreaManagement(this.form.executiveManagement.value);
        } else {
            this.form.areaManagement.disable();
        }
    }
}
